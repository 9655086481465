<template>
  <div class="bs-category">
    <div ref="cateWrapRef" class="bs-cate-wrap" :style="styleObj">
      <TabHeader
        v-if="!hasInitView || !isOneCate"
        ref="headerRef"
        :tabData="topNav"
        :loading="topLoading"
        :active-key="activeChannel?.channel?.id"
        @change="(data) => afterEmit(data,changeChannel)"
        @expose="handleExpose"
      />
      <slot name="freeshipping" />
      <div class="bs-cate-content" :style="{top:contentTop}">
        <Left
          ref="leftRef"
          class="bs-cate-left"
          :cateType="cateType"
          :loading="leftLoading"
          :list="leftContent?.contents"
          :activeChannel="activeChannel"
          :activeLeftContent="activeLeftContent"
          @clickCate="(data) => afterEmit(data,changeLeftContent)"
          @expose="handleExpose"
        />
        <Right
          ref="rightRef"
          class="bs-cate-right"
          :cateType="cateType"
          :list="rightContent.contents"
          :loading="rightLoading"
          :activeChannel="activeChannel"
          :activeLeftContent="activeLeftContent"
          :canNextPage="canNextPage"
          @handleBoundary="(data) => afterEmit(data, handleBoundary)"
          @markPoint="(name, params) => emits('markPoint', name, params)"
          @preload="(url, type) => emits('preload', url, type)"
          @notify="notify"
          @expose="handleExpose"
        />
      </div>
    </div>
  </div>
</template>

<script name="BCategory" setup lang="ts">
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_Category, DS_Category } from '../../types'
import {ref, onMounted, computed, defineEmits, defineExpose, nextTick, watch, provide} from 'vue'
import TabHeader from './components/header.vue'
import Left from './components/Left.vue'
import Right from './components/Right.vue'
import useData from '../../common/hooks/useData.js'
import useAnalysis from '../../common/hooks/useAnalysis.js'
import { template } from '@shein/common-function'
import { useAnalysis as useSdkAnalysis } from '../../common/analysisSource'
import { provideCutImg } from '../../common/hooks/useCutImg'


const cateType = 'cat'
const appConfigs = useAppConfigs()

provideCutImg(appConfigs)

interface IProps {
  fsData?: DS_Category.CATE_FS_DATE
  dataSource?: DS_Category.DataSource
  analysisSource?: AS_Category.AnalysisSource
  swipeUpText?: string
  showFreeShipping?: boolean
  layoutTop?: string
  isPreview?: boolean
  hasTopBanner?: boolean
  autoFetch?: boolean
  customStyle?: object
}

const props = withDefaults(defineProps<IProps>(), {
  isPreview: false,
  showFreeShipping: false,
  hasTopBanner: false,
  autoFetch: true
})

const analysisInstance = useSdkAnalysis(props.analysisSource)

provide('analysisInstance', analysisInstance)

const { triggerNotice } = analysisInstance

const emits = defineEmits(['markPoint', 'preload', 'changeQuery','notify', 'expose'])

const apis = getSource({ appConfigs, dataSource: props.dataSource })

const { getTopNavSa } = useAnalysis(true)

const {
  topNav,
  topLoading,
  changeChannel,
  leftLoading,
  leftContent,
  rightContent,
  rightLoading,
  activeLeftContent,
  activeChannel,
  changeLeftContent,
  getTopNav,
  initFsView,
  hasInitView
} = useData(apis, cateType, getTopNavSa, props.fsData)

if(!props.autoFetch){
  watch(() => props.fsData, (data) => {
    initFsView(data)
  })
}
const contentBottom = ref<string>('50px')
async function afterEmit(data,fn){
  await fn(data)
  const params = {
    channelId: activeChannel?.value?.channel?.id,
    oneCate: activeLeftContent?.value?.index,
  }
  emits('changeQuery', params)
  let type = ''
  if(fn === changeChannel){
    type = 'channel'
  }else if(fn === changeLeftContent){
    type = 'oneCate'
  }else if(fn === handleBoundary){
    type = 'nextPage'
  }
  if(type){
    emits('notify',type, data)
  }
}

function notify(data){
  emits('notify','twoCate', data)
}

function handleExpose(data){
  emits('expose',data)
  // console.log('handleExpose',data)
}

const canNextPage = computed(() => {
  const { index } = activeLeftContent.value
  const nextCate = leftContent.value?.contents?.[index + 1]
  if (nextCate) {
    const style = leftContent?.value?.style?.navStyleInfoList
    const value = style?.find(item => item.confFieldName === 'ScrolldownType') || {}
    const lang = props.swipeUpText || 'Swipe up to continue browsing {0}'
    const nextCateName = nextCate.firstFloorContent?.props?.metaData?.categoryLanguage
    return {
      show: nextCate && value.confValue == 'ON',
      text: template(nextCateName, lang),
      nextCate,
    }
  }
  return { show: false, text: '', nextCate }
})

const isOneCate = computed(() => {
  // if(!hasInitView.value) return true
  return topNav.value?.length <= 1 || props.isPreview
})

function supportsCSSMin() {
  return window.CSS && window.CSS.supports && window.CSS.supports('width', 'min(10vw, 20px)');
}

function getClientWidth(){
  if(typeof window === undefined){

  }
}

let CLIENT_WIDTH = 375
let isSupportCssMin = true
const contentTop = computed(() => {
  // tab:11.733vw + freeshipping:8.53vw
  const { showFreeShipping, isPreview } = props
  let top = 0
  let max = 0
  if(isPreview){
    return top
  }
  if(!isOneCate.value || !hasInitView.value){
    top += 11.733
    max += 1.1733*54
  }
  if(showFreeShipping){
    top += 8.53
    max += 0.853*54
  }
  if(isSupportCssMin){
    return `min(${top}vw,${max}px)`
  }
  return `${Math.min((top * CLIENT_WIDTH) / 100,max)}px`
})

const styleObj = computed(() => {
  const {customStyle, layoutTop} = props
  const bottom = contentBottom.value
  let obj: any = { top:layoutTop, bottom }
  // if(typeof window !== 'undefined'){
  //   // ios与android移动端的滑动问题处理
  //   const UA = window.navigator.userAgent.toLowerCase()
  //   const isIOS = !!(UA && /iphone|ipad|ipod|ios/.test(UA)) || (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
  //   obj.position = isIOS ? 'absolute' : 'fixed'
  // }
  if(customStyle ){
    obj = Object.assign(obj, customStyle)
  }
  return obj
})
onMounted(() => {
  isSupportCssMin = supportsCSSMin()
  if(!isSupportCssMin){
    CLIENT_WIDTH = window.screen.width
  }
  const {fsData,autoFetch} = props
  const hasFsData = fsData && fsData.rightContent
  if(autoFetch && !hasFsData){
    getTopNav(false)
  }
})

function handleBoundary() {
  const nextCate = canNextPage.value?.nextCate
  const { index } = activeLeftContent.value
  changeLeftContent({curLeft: nextCate,index:index+1})
  const item = nextCate?.saInfo
  const topNavSa = activeChannel.value?.channel?.saInfo
  leftRef.value?.refreshLayout()
  triggerNotice({
    id: 'click_turn_page_tip.comp_category',
    data: { item, topNavSa },
  })
  triggerNotice({
    id: 'click_first_category.comp_category',
    data: { item, topNavSa, turn_page_type:'slide' },
  })
}
// 数据源：END

// 内置路由：
// appConfigs.$router.push({ path: `/somepath`, query: { test: 123 } })
// 内置路由：END

const cateWrapRef = ref<HTMLElement | null>(null)
const leftRef = ref<any>(null)
const rightRef = ref<any>(null)
const headerRef = ref<any>(null)

function scrollToSelected(){
  headerRef.value?.scrollToSelected()
}
function refreshTopNav(onlyTopNav = true){
  getTopNav(onlyTopNav)
}

function getBoundRect(){
  if(cateWrapRef.value){
    return cateWrapRef.value.getBoundingClientRect()
  }
  return null
}

function refreshScroll(type?:string){
  if(type === 'left'){
    leftRef.value?.refreshLayout()
  }else if(type === 'right'){
    rightRef.value?.refreshLayout()
  }else{
    leftRef.value?.refreshLayout()
    rightRef.value?.refreshLayout(false)
  }
}
function resetBottom(bottom){
  contentBottom.value = bottom
  nextTick(() => {
    refreshScroll()
  })
}
defineExpose({ refreshTopNav, getBoundRect, refreshScroll, resetBottom,scrollToSelected, initFsView })
</script>

<style lang="less">
.bs-category {
  display: flex;
  justify-content: center;
  .bs-cate-wrap {
    position: fixed;
    top: 11.7vw;
    bottom: 50px;
    width: 100%;
    max-width: 540px;
  }
  .bs-cate-content {
    display: flex;
    position: absolute;
    inset: 0;
    top: 11.73vw;
    // border: 1px solid black;
  }
  .bs-cate-left {
    width: 27.733vw;
    max-width: 150px;
    overflow-y: hidden;
    background: #f6f6f6;
  }
  .bs-cate-right {
    position: relative;
    flex: 1;
    background-color: white;
    width: 72.267vw;
    max-width: 390px;
  }
}
</style>
