import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

import BCategoryVue from './category.vue'
import BSlideCategoryVue from './slideCate.vue'
// import IconVue from './icon.vue'
import BSwichLangCurrencyVue from './components/BSwichLangCurrency/index.vue'
import BSwichDrawerVue from './components/BSwichLangCurrency/SwichDrawer.vue'

export const BSlideCategory = BSlideCategoryVue
export const BCategory = BCategoryVue
export const BSwichLangCurrency = BSwichLangCurrencyVue
export const BSwichDrawer = BSwichDrawerVue
// export const Icon = IconVue

export { default , default as NavIcon } from './icon.vue'
export { default as BCategoryVue } from './category.vue'
// export default IconVue
import type { DS_Category } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getCategoryFsDataRunner(sourceParams?: Partial<DS_Category.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
