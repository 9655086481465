<template>
  <div>
    <ClientOnly>
      <div v-show="loading">
        <SkeletonLeft :cateType="cateType" />
      </div>
    </ClientOnly>
    <IScroll
      ref="iScrollLeft"

      class="bs-cate-left-scroll">
      <ul
        v-if="cateType !== 'sidecat' || !loading"
        class="bs-cate-left-container"
        :class="cateType">
        <li
          v-for="(cate, index) in list"
          :key="index + cate.id || 1"
          v-expose="{
            id: 'expose_first_category.comp_category',
            data: { item: cate?.saInfo, topNavSa: activeChannel?.channel?.saInfo },
            code: 'cat_oneCategory',
            delayReady: 300,
            callback:(data) => emits('expose',data)
          }"
          v-tap="{
            id: 'click_first_category.comp_category',
            data: {
              item: cate?.saInfo,
              topNavSa: activeChannel?.channel?.saInfo,
              turn_page_type: 'click',
            },
          }"
          class="bs-left-item"
          :class="{ active: cate.id == activeLeftContent.cate?.id }"
          aria-hidden="true"
          role="none"
          tabindex="-1"
          @click="emits('clickCate', { curLeft: cate, index })"
        >
          <div
            v-if="cateType === 'sidecat'"
            class="bs-left-item_img img-ctn lazyload"
            :data-background-image="cate?.cover?.src || defaultImgIcon"
            :data-design-width="SIDE_CONTAINER_DESIGN_WIDTH"
          ></div>
          <a
            v-if="cateType === 'sidecat'"
            class="bs-left-item_text"
            :style="{ color: cate.color }"
            href="javascript:;"
            aria-hidden="true"
            role="none"
            tabindex="-1"
          >
            {{ cate?.firstFloorContent?.props?.metaData?.categoryLanguage }}
          </a>
          <a
            v-if="cateType === 'cat'"
            class="bs-left-item_text_cate"
            :style="cate.color ? { color: cate.color } : ''"
            href="javascript:;"
            tabindex="0"
            :aria-label="cate.firstFloorContent && cate.firstFloorContent.props.metaData.categoryLanguage"
          >
            <span>
              {{ cate.firstFloorContent && cate.firstFloorContent.props.metaData.categoryLanguage }}
            </span>
            <span
              v-if="shadowAfterIndex === index"
              class="cate-one__shadow-after"
              :style="`height: ${shadowAfterHeight}`"
            ></span>
          </a>
          <Icon
            v-if="cateType === 'sidecat'"
            class="bs-left-item_arr"
            name="sui_icon_more_right_16px"
            :is-rotate="cssRight"
            size="16px"
            color="#959595"
          />
        </li>
      </ul>
      <slot></slot>
    </IScroll>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch, nextTick, defineExpose, inject } from 'vue'
import ClientOnly from 'vue-client-only'
import IScroll from './scroll/index.vue'
import { Icon } from '@shein-aidc/icon-vue2'
// import { transformImg, replaceHrefSpecChar } from '@shein/common-function'
import SkeletonLeft from '../skeleton/left.vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const {vTap, vExpose} = inject('analysisInstance')

const emits = defineEmits(['clickCate', 'expose'])
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  list: {
    type: Array,
    default: () => [],
  },
  cateType: {
    type: String,
    default: 'sidecat',
  },
  activeChannel: {
    type: Object,
    default: () => ({}),
  },
  activeLeftContent: {
    type: Object,
    default: () => ({}),
  },
  shadowAfterIndex: {
    type: Number,
    default: undefined,
  },
  shadowAnimate: {
    type: Boolean,
    default: false,
  },
  shadowAfterHeight: {
    type: String,
    default: 'auto',
  },
  shadowBeforeIndex: {
    type: Number,
    default: undefined,
  },
})

const { cssRight = false } = useAppConfigs().$envs || {}
const defaultImgIcon = '//img.ltwebstatic.com/images3_ccc/2024/08/15/c5/1723693827bb4d1c73e11ee62f3699bdcc6a51e741.webp'
const SIDE_CONTAINER_DESIGN_WIDTH = 88
const iScrollLeft = ref(null)

function refreshLayout(resetTranslate = true){

  nextTick(() => {
    iScrollLeft.value?.refresh(resetTranslate)
    if(props.cateType === 'sidecat') return
    const index = props?.activeLeftContent?.index
    if(index > 0){
      // 滑动到一级导航的位置
      const beforeElement = iScrollLeft.value?.$el.querySelectorAll('.bs-left-item')[index - 1]
      const y = beforeElement.offsetTop || 0
      // console.log('reset height',y)
      iScrollLeft.value?.scrollTo({ y, time: 300 })
    }
  })
}
watch(
  () => props.list,
  () => {
    refreshLayout()
  },
)
defineExpose({refreshLayout})
</script>
<style lang="less">
.bs-cate-left-scroll{
  height: 100%;
}
.bs-cate-left-container {
  background: white;
  padding-bottom: 7.5px;
  &.cat {
    padding-bottom: 0;
    background-color: #f6f6f6;
    .bs-left-item_img,
    .bs-left-item_arr {
      display: none !important;
    }
    .bs-left-item {
      padding: 10px 3px 10px 7px;
      background-color: #f6f6f6;
      &.active {
        background-color: #000;
        /* stylelint-disable-next-line selector-max-specificity */
        .bs-left-item_text_cate {
          color: #fff;
          font-weight: 700;
        }
      }
    }
    .bs-left-item_text_cate {
      text-decoration: none;
    }
  }
  &.sidecat {
    .bs-left-item {
      background-color: white;
    }
  }
  .bs-left-item {
    padding-left: 20px;
    padding-top: 7.5px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    &_img {
      width: 11.733vw;
      height: 11.733vw;
      width: min(11.733vw,64px);
      height: min(11.733vw,64px);
      display: inline-block;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #fafafa;
      background-position: center;
      border: 1px solid #e5e5e5;
    }
    &_text {
      font-size: 3.733vw;
      font-size: min(3.733vw,20px);
      flex: 1;
      text-align: left;
      padding-left: 12px;
      color: #222;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-decoration: none;
    }
    &_text_cate {
      position: relative;
      display: block;

      line-height: 1.2083;
      display: flex;
      align-items: center;
      color: #222;
      span {
        width: 100%;
        margin: 0 auto;
        font-size: 12px;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
