<template>
  <div class="bs-c-swich-slide__wrap">
    <!-- 语种 -->
    <section
      v-if="showType == 'language' && langs.length > 0"
      class="bs-c-swich-slide__content"
    >
      <div
        v-for="(item, index) in langs"
        :key="index"
        class="bs-c-swich-slide__content-item j-change-currency"
        @click="changeHandle(item.web_lang, '')"
      >
        <div class="bs-c-swich-slide__item-left">
          {{ item.title }}
        </div>
        <div
          v-if="popVal == item.web_lang"
          class="bs-c-swich-slide__item-right"
        >
          <Select />
        </div>
      </div>
    </section>

    <!-- 币种 -->
    <section
      v-if="showType == 'currency'"
      class="bs-c-swich-slide__content"
    >
      <div
        v-for="(item, index) in currencys"
        :key="index"
        class="bs-c-swich-slide__content-item j-change-currency"
        @click="changeHandle(item.code, item.value)"
      >
        <div class="bs-c-swich-slide__item-left">
          <span class="bs-c-swich-slide__item-currency__code">
            {{ item.code }}
          </span>
          <span class="bs-c-swich-slide__item-currency__unit">
            {{ item.symbol_left }} {{ item.symbol_right }}
          </span>
        </div>
        <div
          v-if="popVal == item.code"
          class="bs-c-swich-slide__item-right"
        >
          <Select />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import Select from './icons/Select.vue'
import type { C_SwichLangCurrency } from '../../../../types'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../../../common/dataSource'
const appConfigs = useAppConfigs()
const apis = getSource({ appConfigs })
import { removeCookie, windowCacheDeleteCb, parseQueryString, extractQueryString, stringifyQueryString } from '@shein/common-function'

const props = defineProps<{
  showType?: String
  languageList?: C_SwichLangCurrency.LANG_LIST
  currencyInfos?: C_SwichLangCurrency.CURRENCY_LIST
}>()

const langs = ref<C_SwichLangCurrency.LANG_LIST>([])
const currencys = ref<C_SwichLangCurrency.CURRENCY_LIST>([])
const popVal = ref<string>('')

const setData = async (val) => {
  if (!val) return
  if (val == 'currency') {
    popVal.value = appConfigs.$envs.currency
    // 如果外部有传入则不调接口
    if (props.currencyInfos) {
      currencys.value = props.currencyInfos
      return
    }
    // 获取币种列表
    const res = await apis.getCurrencys()
    currencys.value = res.info.currency_infos
    return
  }
  popVal.value = appConfigs.$envs.lang
  // 如果外部有传入则不调接口
  if (props.languageList?.length) {
    langs.value = props.languageList
    return
  }
  // 获取语种列表
  const res = await apis.getlangs()
  langs.value = res.info.language_list
}

watch(() => props.showType, async val => setData(val), { immediate: true })

const changeHandle = async (item, rate) => {
  if (!props.showType) return
  if (props.showType == 'currency') {
    // 设置币种列表
    const res = await apis.setCurrency(item)
    if (res.code == '0') {
      await windowCacheDeleteCb({ keys: ['dynamicData', 'homeDynamicData', 'campaignsDynamicData'] })
      const querys: any = parseQueryString(location.search)
      delete querys.currency
      querys._t = Date.now()
      let link = `${location.pathname}?${stringifyQueryString({ queryObj: querys })}`
      if (rate) {
        const min_price = link.match(/min_price=(\d+)/) && link.match(/min_price=(\d+)/)[1] * rate
        const max_price = link.match(/max_price=(\d+)/) && link.match(/max_price=(\d+)/)[1] * rate

        min_price && (link = link.replace(/min_price=\d+/, `min_price=${Math.floor(min_price)}`))
        max_price && (link = link.replace(/max_price=\d+/, `max_price=${Math.ceil(max_price)}`))
      }
      location.href = link
    }
    return
  }
  // 设置语种列表
  const res = await apis.setlang(item)
  if (res.code == '0') {
    removeCookie({ key: `pwa_${appConfigs.$envs.siteUID}_${appConfigs.$envs.appLanguage}_abt` })
    await windowCacheDeleteCb({
      keys: ['dynamicData', 'homeDynamicData', 'campaignsDynamicData'],
    })
    const query: any = parseQueryString(extractQueryString(location.href)) || {}
    let searchParams = { ...query, ...{ _t: Date.now() } }
    if(searchParams?.lang != '0' && langs.value.some(_ => _.web_lang == searchParams.lang)) {
      searchParams.lang = '0'
    }
    location.href = `${location.pathname}?${stringifyQueryString({ queryObj: searchParams })}`
  }
}
</script>

<style lang="less" scoped>
@vw: 375 / 100vw;
.bs-c-swich-slide {
  &__wrap {
    position: absolute;
    inset: 0;
    overflow: auto;
  }
  &__content {
    padding: 0 0 24 / @vw 15 / @vw;
    padding: 0 0 min(24 / @vw, 34.5px) min(15 / @vw, 21.6px);
  }
  &__content-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e5e5e5;
    padding-right: 12 / @vw;
    height: 44 / @vw;
    line-height: 44 / @vw;
    font-size: 14 / @vw;

    padding-right: min(12 / @vw, 54px);
    height: min(44 / @vw,64px);
    line-height: min(44 / @vw,64px);
    font-size: min(14 / @vw, 20px);
    &:first-child {
      border-top: 0;
    }
  }
  &__item-currency__code {
    width: 37.5 / @vw;
    margin-right: 26 / @vw;

    font-weight: 700;
    width: min(37.5 / @vw, 54px);
    margin-right: min(26 / @vw, 37.44px);
    color: #767676;
  }
  &__item-currency__unit {
    color: #767676;
  }
  &__item-left {
    display: flex;
  }
  &__item-right {
    font-size: 16 / @vw;
    font-size: min(16 / @vw, 23px);
  }
}
</style>
